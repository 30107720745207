<template>
	<v-container fluid class="petu-content pb-0">
        <background />
        <!-- <v-row>
            <v-col class="px-4" cols="12"> -->
                <v-card flat class="rounded-xl shadow">
                    <v-card-title class="justify-center petu-green">Checkout</v-card-title>

                    <v-alert type="error" text tile dense v-show="errors.length > 0">
                        <ul>
                            <template v-for="(err, ix) in errors">
                                <li :key="ix">{{ err }}</li>   
                            </template>
                        </ul>
                    </v-alert>

                    <div v-if="cart != null && cart.products.length > 0">
                        <v-list class="mt-3">
                            <template v-for="prod in cart.products">
                                    
                                <v-list-item ref="content" class="card-content" :key="prod.productId">
                                    <v-badge :content="prod.qty" color="primary" bordered overlap offset-x="25" offset-y="20" left>
                                        <v-list-item-avatar>
                                            <v-avatar>
                                                <v-img :src="getProductImage( prod.productId )"></v-img>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                    </v-badge>
                                    
                                    <v-list-item-content>
                                        <v-list-item-title>{{ getProductName( prod.productId ) }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ getProducPrice( prod.productId ) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    
                                    <v-list-item-action>
                                        <div>{{ getProductTotal( prod ) }}</div>
                                    </v-list-item-action>
                                </v-list-item>
                                    
                            </template>
                        </v-list>

                        <v-divider class="my-4"></v-divider>

                        <v-container fluid>
                            <v-row class="mx-2">
                                <v-col cols="6" class="px-0 pb-0 grey--text">Subtotal</v-col>
                                <v-col cols="6" class="text-right px-0 pb-0 grey--text">{{ getCartSubtotal() }}</v-col>

                                <v-col cols="6" class="px-0 pb-0 grey--text" v-if="couponVisible">Coupons</v-col>
                                <v-col cols="6" class="text-right px-0 pb-0 grey--text" v-if="couponVisible"> - {{ getCartDiscounts() }}</v-col>

                                <!-- <v-col cols="12" class="text-right px-0 pb-0" v-if="couponVisible">
                                    <template v-for="(coupon, ix) in coupons">
                                        <v-col cols="2" class="pt-0 pb-1">
                                            <v-btn icon small text color="red" @click="removeCoupon(coupon)"><v-icon small>mdi-close</v-icon></v-btn>
                                        </v-col>
                                        <v-col cols="6" class="pt-0 pb-1" align-self="center">
                                            <span class="awesome-mini-cart-drawer-text">{{ coupon.code }}</span>
                                        </v-col>
                                        <v-col cols="4" class="text-right pt-0 pb-1" align-self="center" style="border-right: 2px solid #cdcdcd;">
                                            <span class="awesome-mini-cart-drawer-text">- {{ formatMoney(coupon.amount) }}</span>
                                        </v-col>
                                    </template>
                                </v-col> -->

                                <v-col cols="6" class="px-0 grey--text">Tax</v-col>
                                <v-col cols="6" class="text-right px-0 grey--text">{{ getCartTax() }}</v-col>

                                <v-col cols="6" class="px-0 title grey--text text--darken-2">Total</v-col>
                                <v-col cols="6" class="text-right  px-0 title grey--text text--darken-2">{{ getCartTotal() }}</v-col>
                            </v-row>
                        </v-container>
                    </div>

                    <div class="text-center pa-3" v-else>
                        <v-icon size="64">mdi-cart-outline</v-icon>
                        <div class="headline my-3 grey--text text--darken-1">El carrito está vacío</div>

                        <v-btn depressed to="/productos" color="primary" class="no-uppercase">Ver productos</v-btn>
                    </div>

                    <!-- <v-card-text class="">
                        <v-row>
                            <v-col cols="9" class="text-left">
                                <div><a class="petu-green" style="text-decoration: none;" href="tel:9394896563">939.489.6563</a></div>
                                <div><a class="petu-green" style="text-decoration: none;" href="mailto:petu.power20@gmail.com">petu.power20@gmail.com</a></div>
                            </v-col>
                            <v-col cols="3" class="text-right">
                                <v-img src="@/assets/IG-01.svg"></v-img>
                            </v-col>
                        </v-row>
                    </v-card-text> -->

                    <v-card-text class="billingAddress" v-if="cart != null && cart.products.length > 0">
                        <v-card flat color="grey lighten-3">
                            <v-card-title class="subtitle-1">
                                Dirección de facturación

                                <v-spacer></v-spacer>

                                <v-btn small icon @click="editAddress(billingAddress, 'billing')"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <address-component :value="billingAddress" />
                            </v-card-text>
                        </v-card>
                    </v-card-text>

                    <v-card-text class="shippingAddress" v-if="cart != null && cart.products.length > 0" v-show="needShipping">
                        <v-card flat color="grey lighten-3">
                            <v-card-title class="subtitle-1">
                                Dirección de envío

                                <v-spacer></v-spacer>

                                <v-btn small icon @click="editAddress(shippingAddress, 'shipping')"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <address-component :value="shippingAddress" />
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                </v-card>
            <!-- </v-col>
        </v-row> -->
                        
		<div class="bottom" v-if="cart != null && cart.products.length > 0">
            <v-btn depressed block x-large color="primary" @click="validateAndPay" :loading="validatingCheckout">Pagar</v-btn>
        </div>

        <v-bottom-sheet v-model="showPaymentMethods">
            <v-card class="text-center" tile>
                <v-card-text class="pa-3">
                    <v-subheader>Métodos de pago</v-subheader>
                    <div>
                        <ath-movil env="production" :subtotal="subtotal" :tax="tax" :total="total" :items="items" @click="showPaymentMethods = false" 
                            @success="processWithAthMovil" @cancel="showAthMovilMessage" @expire="showAthMovilMessage" />
                    </div>
                    
                    <div class="divider">
                        <span>o</span>
                    </div>

                    <div class="stripePayment">
                        <div class="stripeButton" @click.prevent="stripeDialog = true">
                            Pagar con tarjeta
                        </div>
                        <div class="text-center stripeCards">
                            <img src="../assets/cards/visa.svg" alt="visa" width="32">
                            <img src="../assets/cards/mastercard.svg" alt="mastercard" width="32">
                            <img src="../assets/cards/discover.svg" alt="discover" width="32">
                            <img src="../assets/cards/amex.svg" alt="amex" width="32">
                            <img src="../assets/cards/jcb.svg" alt="jcb" width="32">
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

        <v-dialog v-model="stripeDialog">
            <v-card>
                <v-card-title>
                    Tarjeta de crédito

                    <v-spacer></v-spacer>

                    <v-btn icon @click="stripeDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <!-- <v-card-subtitle>Pagar con una tarjeta de crédito existente o añadir una nueva.</v-card-subtitle> -->
                <v-card-text>
                    <v-alert type="error" text class="caption" v-show="stripeError.length > 0">{{ stripeError }}</v-alert>
                    <v-container class="card mb-3" v-show="showSavedCard && view == 'saved'">
                        <v-row v-for="(card, ix) in customer.cards" :key="ix" :class="{'selected': ix == selectedCard}" class="rounded mb-3" @click="selectedCard = ix">
                            <v-col cols="3" style="display:flex;">
                                <img :src="require(`@/assets/cards/${card.brand == '' ? 'visa' : card.brand.toLowerCase().replace(/\s/g, '')}.svg`)" width="50" />
                            </v-col>
                            <v-col cols="7">
                                <div>
                                    <div class="caption">᛫᛫᛫᛫ ᛫᛫᛫᛫ ᛫᛫᛫᛫ {{ card.last4 }}</div>
                                    <div class="caption">Exp. {{ card.expMonth }}/{{ card.expYear }}</div>
                                    <div class="caption red--text" v-show="isCardExpired(card)">Expired</div>
                                    <!-- <div v-show="ix == selectedCard">
                                        <v-chip color="success" small label>
                                            <v-avatar left>
                                                <v-icon small>mdi-check</v-icon>
                                            </v-avatar>
                                            Selected
                                        </v-chip>
                                    </div> -->
                                </div>
                            </v-col>
                            <v-col cols="2" style="display:flex; flex-direction: column;">
                                <v-icon small class="mt-3" @click.stop="openWarning(ix)">mdi-delete-outline</v-icon>
                            </v-col>
                        </v-row>

                        <v-btn block depressed class="text-none white--text" color="blue lighten-1" @click="view = 'new'">
                            <v-icon left>mdi-plus</v-icon>
                            Añadir tarjeta
                        </v-btn>
                    </v-container>
                    <stripe-element-card ref="stripeCard" :pk="pk" @token="tokenCreated" v-show="!showSavedCard || view == 'new'" />

                    <div class="caption">** Petu Power no almacena la información de tarjetas de crédito.</div>                    
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed block color="primary" @click="processWithStripe" :loading="processing">Pagar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addressDialog">
            <v-card>
                <v-card-title>
                    Modificar dirección

                    <v-spacer></v-spacer>

                    <v-btn icon @click="addressDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <address-component v-model="selectedAddress" :editable="true" dense ref="address" />
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed block color="primary" @click="saveAddress" :loading="savingAddress">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="warningDialog">
            <v-card>
                <v-card-title>
                    Warning
                    <v-spacer></v-spacer>
                    <v-btn icon @click="warningDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    Estas seguro que quieres eliminar esta tarjeta?
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed block color="error" @click="deleteCard" :loading="deletingCard">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
	</v-container>
</template>
<script>
import Background from '../components/Background.vue';
import AthMovil from '../components/AthMovil';
import AddressComponent from '../components/AddressComponent';
import { _st } from '@/softech';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { PetuCheckout } from '../classes/checkout';
import { mapGetters, mapActions } from 'vuex';
import { PetuProduct } from '../classes/product';
import moment from 'moment';
import { PetuCustomer } from '../classes/customer';
import { StripeGateway } from '../classes/gateways/stripe';

export default {
	name		: 'Checkout',
	components	: { Background, AddressComponent, StripeElementCard, AthMovil },
    data() {
        return {
            pk                  : process.env.NODE_ENV === 'production'
                                    ? 'pk_live_51J4p4sEY7dPrD5c4yPfgm6WR61q4pgK6CXfndEbVeOvGnsvcLOkcuqbFIOa0PWSOJ0M6ix8iBxb1u5hE05cUnUpg00JQNHyBxH'
                                    : 'pk_test_51J4p4sEY7dPrD5c4Ow5S5bHLFOiHAbLyG5Fc93AqBTtS2eiUoJ70eBb9Hjg92srTweU5Uy8M03b2HL1cSQ4cxOgq00iGxQLBEY',
            productImages       : [],
            showPaymentMethods  : false,

            checkout            : null,
            validatingCheckout  : false,
            stripeDialog        : false,
            addressDialog       : false,
            warningDialog       : false,
            processing          : false,
            deletingCard        : false,

            view                : 'saved',

            errors              : [],
            stripeError         : '',

            selectedAddress     : null,
            addressType         : '',
            savingAddress       : false,

            selectedCard        : 0,
            cardForDelete       : null,
        }  
    },
    async mounted() {
        await this.loadCart();
        this.checkout = new PetuCheckout();
    },
    methods: {
        ...mapActions({
            updateCustomer  : 'auth/updateCustomer',
            reloadCustomer  : 'auth/reloadCustomer',
            loadCart        : 'cartInfo/getCartData',
        }),
        formatCurrency(ammount) {
            return _st.toMoney(ammount);
        },
        getProductImage( productId ) {
            let p = this.productImages.find(p => {
                return p.id == productId
            });

            return p?.src;
        },
        getProductName( productId ) {
            let product = this.products.find(p => {
                return p.id == productId;
            });

            if( _st.isNU( product ) ) return '';
            return product.name;
        },
        getProducPrice( productId ) {
            let product = this.products.find(p => {
                return p.id == productId;
            });

            if( _st.isNU( product ) ) return _st.toMoney( 0 );

            let p = new PetuProduct( product );
            return _st.toMoney( p.getPrice() );
        },
        getProductTotal( product ) {
            let prod = this.products.find(p => {
                return p.id == product.productId;
            });

            if( _st.isNU( prod ) ) return _st.toMoney( 0 );

            let p = new PetuProduct( prod );
            return _st.toMoney( p.getPrice() * product.qty );
        },
        getCartSubtotal() {
            return _st.toMoney( this.subtotal );
        },
        getCartTax() {
            return _st.toMoney( this.tax );
        },
        getCartDiscounts() {
            return _st.toMoney( this.discounts );
        },
        getCartTotal() {
            return _st.toMoney( this.total );
        },

        async processWithStripe() {
            this.errors = [];
            this.stripeError = '';
            this.processing = true;

            try {
                // create card token
                if( !this.showSavedCard || this.view == 'new' ) {
                    this.$refs.stripeCard.submit();
                    return;
                }

                // process order
                this.checkout.paymentMethod = 'stripe';
                let orderId = await this.checkout.process({ cardIx: this.selectedCard });

                if( !orderId && this.checkout.errors.length > 0 ) {
                    console.error( this.checkout.errors );
                    this.errors = this.checkout.errors;
                    this.processing = false;
                    return;
                }

                // go to thank you page
                this.$router.push(`/thankyou/${orderId}`);
                this.reloadCustomer();
            }
            catch( error ) {
                console.log( error );
                this.stripeError = 'Hubo un error procesando su pago. Verifique que la información de su tarjeta este correcta o entre una tarjeta nueva.';
                this.processing = false;
            }
        },
        async tokenCreated( token ) {
            // process order
            try {
                this.checkout.cardToken = token.id;
                this.checkout.paymentMethod = 'stripe';
                let orderId = await this.checkout.process({ cardIx: this.selectedCard });

                if( !orderId ) {
                    console.error( this.checkout.errors );
                    return;
                }

                // go to thank you page
                this.$router.push(`/thankyou/${orderId}`);
                this.reloadCustomer();
            }
            catch( error ) {
                console.error(error);
                this.processing = false;
            }
        },
        async processWithAthMovil( response ) {
            try {
                this.checkout.paymentMethod = 'athmovil';
                let orderId = await this.checkout.process({ athmovilConfirmation: response.data.referenceNumber });

                // go to thank you page
                this.$router.push(`/thankyou/${orderId}`);
                this.reloadCustomer();
            }
            catch( error ) {
                console.log( error );
                this.processing = false;
            }
        },
        showAthMovilMessage( response ) {
            console.log(response);
        },
        async validateAndPay() {
            this.validatingCheckout = true;
            this.checkout.cart = this.cart;
            this.checkout.paymentMethod = 'stripe';

            if( !await this.checkout.validate() ) {
                // mostrar los errores
                this.errors = this.checkout.errors;
                this.validatingCheckout = false;
                return;
            }

            this.validatingCheckout = false;
            this.showPaymentMethods = true;
        },
        editAddress( address, type ) {
            this.selectedAddress = address;
            this.addressType = type;
            this.addressDialog = true;
        },
        async saveAddress() {
            // this.$refs.address.resetValidation();

            // check if form is validate
            if( !this.$refs.address.isValid() )
                return;

            this.savingAddress = true;
            let c = new PetuCustomer( this.customer );
            
            if( this.addressType == 'billing' ) {
                c.data.billingAddress1 = this.selectedAddress.line1;
                c.data.billingAddress2 = this.selectedAddress.line2;
                c.data.billingCity = this.selectedAddress.city;
                c.data.billingState = this.selectedAddress.state;
                c.data.billingZipcode = this.selectedAddress.zipCode;
            }
            else if( this.addressType == 'shipping' ) {
                c.data.shippingAddress1 = this.selectedAddress.line1;
                c.data.shippingAddress2 = this.selectedAddress.line2;
                c.data.shippingCity = this.selectedAddress.city;
                c.data.shippingState = this.selectedAddress.state;
                c.data.shippingZipcode = this.selectedAddress.zipCode;
            }

            await c.save();
            this.updateCustomer({ customer: c });

            this.savingAddress = false;
            this.addressDialog = false;
        },
        isCardExpired( card ) {
            let expDate = moment( new Date( card.expYear, card.expMonth, 0) );
            return moment().isAfter( expDate );
        },
        openWarning( ix ) {
            this.cardForDelete = ix;
            this.warningDialog = true;
        },
        async deleteCard() {
            try {
                let stripe = new StripeGateway({});
                await stripe.deleteCard({
                    customerId: this.customer.stripeCustomerId,
                    cardId: this.customer.cards[ this.cardForDelete ].stripeCardId
                });

                this.reloadCustomer();
                this.cardForDelete = null;
                this.warningDialog = false;
            }
            catch(error) {
                console.log( error );
                this.deletingCard = false;
            }
        },
    },
    computed: {
        ...mapGetters({
            customer    : 'auth/customer',
            cart        : 'cartInfo/cart',
            products    : 'cartInfo/cartProducts',
            coupons     : 'cartInfo/cartCoupons',
            subtotal    : 'cartInfo/cartSubtotal',
            tax         : 'cartInfo/cartTax',
            shipping    : 'cartInfo/cartShipping',
            discounts   : 'cartInfo/cartDiscounts',
            total       : 'cartInfo/cartTotal',
            needShipping: 'cartInfo/cartNeedShipping',
        }),
        couponVisible() {
            return this.coupons.length > 0;
        },
        billingAddress() {
            return {
                name: `${this.customer.firstName} ${this.customer.lastName}`,
                line1: this.customer.billingAddress1,
                line2: this.customer.billingAddress2,
                city: this.customer.billingCity,
                state: this.customer.billingState,
                zipCode: this.customer.billingZipcode
            }
        },
        shippingAddress() {
            return {
                name: `${this.customer.firstName} ${this.customer.lastName}`,
                line1: this.customer.shippingAddress1,
                line2: this.customer.shippingAddress2,
                city: this.customer.shippingCity,
                state: this.customer.shippingState,
                zipCode: this.customer.shippingZipcode
            }
        },
        items() {
            if( _st.isNU( this.cart ) )
                return [];

            let items = [];
            this.cart.products.forEach(p => {
                let price = 0;
                let product = this.products.find(p2 => {
                    return p2.id == p.productId;
                });

                if( !_st.isNU( product ) ) {
                    let pp = new PetuProduct( product );
                    price = pp.getPrice();
                }

                items.push({
                    name: this.getProductName( p.productId ),
                    quantity: p.qty,
                    price: price,
                    tax: 0.00
                })
            });

            return items;
        },
        showSavedCard() {
            return this.customer.cards.length > 0;
        },
    },
    watch: {
        async products() {
            for(let p of this.products) {
                let pp = new PetuProduct( p );
                let src = await pp.getProductImage();
                this.productImages.push({
                    id      : pp.data.id,
                    src     : src
                });
            }
        },
        stripeDialog(v) {
            if( !v ) this.view = 'saved';
        }
    }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center !important
}
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
.stripeButton {
    margin: 0 16px;
    padding: 8px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    background-color: #283593;
    cursor: pointer;
}
/* .stripePayment {
    border: 1px solid #cdcddc;
    padding: 8px 0;
    border-radius: 5px;
    margin: 0 16px;
} */
.stripeCards {
    margin-top: 5px;
}
.stripeCards img {
    margin: 0 5px;
}
.divider {
    width: 90%;
    text-align: center; 
    border-bottom: 1px solid #cdcdcd; 
    color: #929292;
    line-height: 0.01em;
    margin: 30px auto;
    padding: 0 16px;
} 

.divider span { 
    background:#fff; 
    padding:0 15px;
}
.bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    /* height: 50px; */
    width: 100%;
    background-color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    border-radius: 10px;
}
.card .selected {
    background-color: rgb(201, 235, 201);
}
</style>
