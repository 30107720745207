<template>
    <div class="text-center">
        <div id="ATHMovil_Checkout_Button" class="elevation-0" @click="$emit('click')"></div>
    </div>
</template>

<script>
import { _st } from '@/softech';
export default {
    props: {
        env         : { type: String, default: 'production' },
        subtotal    : { type: Number, default: 0 },
        tax         : { type: Number, default: 0 },
        total       : { type: Number, default: 0 },
        items       : { type: Array, default() { return [] } }
    },
    data() {
        return {
            athmCheckout: {
                env: this.env,
                publicToken: this.env == 'production' ? 'd8924b78d863e6fd917a9350ef6a63666cad1d74' : 'sandboxtoken01875617264',

                timeout: 600,

                theme: 'btn',
                lang: 'es',

                total: _st.toFixedNumber( this.total, 2 ),
                tax: _st.toFixedNumber( this.tax, 2 ),
                subtotal: _st.toFixedNumber( this.subtotal, 2 ),

                items: this.items,

                onCompletedPayment: null,
                onCancelledPayment: null,
                onExpiredPayment: null,
            },
            athmV3: null
        }
    },
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    watch: {
        env(v) {
            this.athmCheckout.env = v;

            if( v == 'production' )
                this.athmCheckout.publicToken = 'd8924b78d863e6fd917a9350ef6a63666cad1d74'; // cambiar por la variable de settings
            else
                this.athmCheckout.publicToken = 'sandboxtoken01875617264';

            this.update();
        },
        subtotal(v) {
            this.athmCheckout.subtotal = _st.toFixedNumber( v, 2 );
            this.update();
        },
        tax(v) {
            this.athmCheckout.tax = _st.toFixedNumber( v, 2 );
            this.update();
        },
        total(v) {
            this.athmCheckout.total = _st.toFixedNumber( v, 2 );
            this.update();
        },
        items(v) {
            this.athmCheckout.items = v;
            this.update();
        }
    },
    methods: {
        init() {
            if( this.env == 'production' )
                this.athmCheckout.publicToken = 'd8924b78d863e6fd917a9350ef6a63666cad1d74'; // cambiar por la variable de settings
            else 
                this.athmCheckout.publicToken = 'sandboxtoken01875617264';

            this.update();
            require('../plugins/athmovilV3.1');

            this.athmCheckout.onCompletedPayment = response => {
                console.log('onCompletedPayment');
                this.$emit('success', response);
            };
            this.athmCheckout.onCancelledPayment = response => {
                console.log('onCancelledPayment');
                this.$emit('cancel', response);
            };
            this.athmCheckout.onExpiredPayment = response => {
                console.log('onExpiredPayment');
                this.$emit('expire', response);
            };

            this.update();
        },
        update() {
            window.ATHM_Checkout = this.athmCheckout;
        }
    },
    beforeDestroy() {
        delete window.ATHM_Checkout;
    }
}
</script>

<style lang="scss" scoped>

</style>