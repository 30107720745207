/*
 * Author: Roberto Torres
 * Company: Softech Corporation
 * Version: 1.0.0
 * Date: 2021-06-21
 * 
 * Description: 
 * Class to facilitate the creation and manipulation of 
 * the checkout in the Petu App
 * 
 */

import { _st } from "@/softech";
import store from '@/store';
import { PetuCart } from "./cart";
import { PetuOrder } from "./order";
import { PetuCustomer } from "./customer";

export class PetuCheckout {
    constructor() {
        this.cart           = null;
        this.paymentMethod  = null; // stripe, athmovil
        this.customer       = store.getters['auth/customer'];
        this.cardToken      = '';
        this.subscriptionId = '';
        this.errors         = '';
    }

    async process({ athmovilConfirmation = '', cardIx = null }) { 
        let order = new PetuOrder();
        console.log( athmovilConfirmation, cardIx );

        try {
            await store.dispatch('cartInfo/getCartData');
            // let c = new PetuCart();
            this.cart = store.getters['cartInfo/cart']; // await c.loadFromCustomerId( this.customer?.id );

            let result = await this.validate();
            if( this.paymentMethod == 'athmovil' && _st.isEmpty( athmovilConfirmation ) )
                this.errors.push('ATH Movil confirmation number not received');

            if( result !== true ) {
                console.error( this.errors );
                return false;
            }

            // 1. create order
            // let cart = new PetuCart( this.cart );
            // let customer = new PetuCustomer( this.customer );
            // order.data.status                = OrderStatus.PENDING_PAYMENT;
            // order.data.customerId            = this.customer?.id;
            // order.data.products              = cart.data.products;
            // order.data.subtotal              = await cart.getSubtotal();
            // order.data.discounts             = await cart.getDiscounts();
            // order.data.shipping              = await cart.getShipping();
            // order.data.tax                   = await cart.getTax();
            // order.data.total                 = await cart.getTotal();
            // order.data.coupons               = _st.extractProp( cart.coupons, 'id' );
            // order.data.paymentMethod         = this.paymentMethod;
            // order.data.paymentConfirmation   = '';
            // order.data.fromSubscriptionID    = this.subscriptionId;

            // // set information to the order products
            // let ids = [];
            // order.data.products.forEach(p => ids.push( p.productId ) );
            // // let products = await API.graphql(graphqlOperation(listProducts, { filter: { or: filter }, limit: 500 } ));
            // let products = await (new PetuProduct()).getList( ids.join(',') ); 
            
            // order.data.products.forEach(p => {
            //     // find product info
            //     let pInfo = products.find(pi => { return p.productId == pi.id; });
            //     let obj = new PetuProduct( pInfo );

            //     p.price         = _st.toFixedNumber( obj.getPrice(), 2 );
            //     p.tax           = obj.data.isTaxable ? _st.toFixedNumber( ( p.price * p.qty ) * 0.115 ) : 0; // poner el tax como una variable ***
            //     p.total         = _st.toFixedNumber( p.price + p.tax );
            //     p.isTaxable     = obj.data.isTaxable;
            //     p.name          = obj.data.name;
            //     p.type          = obj.data.type;
            //     p.trainingType  = obj.data.trainingType;
            //     p.dimensions    = obj.data.dimensions;
            //     p.weight        = obj.data.weight;
            //     p.images        = obj.data.images;
            // });

            // await order.save();

            // 2. process payment
            if( this.paymentMethod == 'stripe' ) {
                // proces order using stripe
                await order.process( 'stripe', this.cardToken, cardIx );

                // let stripe = new StripeGateway({});
                
                // // check if the customer has a stripe customer id
                // if( _st.isNUE( customer.data.stripeCustomerId ) ) {
                //     let tmp = await stripe.createCustomer({
                //         id: customer.data.id,
                //         email: customer.data.email,
                //         name: `${customer.data.firstName} ${customer.data.lastName}`,
                //         phone: customer.data.phone,
                //         cardToken: _st.isEmpty( this.cardToken ) ? null : this.cardToken
                //     });

                //     customer.data.cards.push( tmp );
                // }

                // // check if the a card is set
                // else if( customer.data.cards.length == 0 || !_st.isNUE( this.cardToken ) ) {
                //     if( customer.data.cards.length > 0 ) {
                //         await stripe.deleteCard({
                //             customerId: customer.data.stripeCustomerId,
                //             cardId: customer.data.cards[0].stripeCardId
                //         });
                //     }

                //     let card = await stripe.createCard({
                //         customerId: customer.data.id,
                //         stripeCustomerId: customer.data.stripeCustomerId,
                //         cardToken: this.cardToken
                //     });
                    
                //     if( _st.isNU( card ) ) {
                //         this.errors.push( 'Hubo un error procesando su tarjeta.' );
                //         await order.delete();
                //         return false;
                //     }
                    
                //     customer.data.cards.push({
                //         id          : card.id,
                //         brand       : card.brand,
                //         last4       : card.last4,
                //         expMonth    : card.expMonth,
                //         expYear     : card.expYear
                //     });
                    
                //     // await customer.save();
                //     // store.dispatch('auth/updateCustomer', { customer });
                // }
                
                // stripe.customer = customer.data.stripeCustomerId;
                // stripe.card = customer.data.cards[0]?.stripeCardId;
                
                // let charge = await stripe.charge({
                //     amount: order.data.total,
                //     description: `Payment for order #${order.data.orderNumber}`
                // });

                // order.data.paymentConfirmation = charge;
            }
            else if( this.paymentMethod == 'athmovil' ) {
                // process order with athmovil
                // order.data.paymentConfirmation = athmovilConfirmation;
                await order.process( 'athmovil', athmovilConfirmation );
            }

            // // 3. update order status and payment info
            // order.data.status = OrderStatus.PROCESSING;
            // await order.save();

            // // 4. clear cart content
            // let orderProducts = await cart.loadProducts(); // cart.products;
            // await cart.clear();

            // // 5. create / update subscriptions
            // let subscriptions = await customer.getSubscriptions();
            // for( let prod of orderProducts ) {

            //     // a. check if the product create a subscription
            //     if( !prod.generateSubscription ) continue;

            //     // b. check if there is a subscription of the same product
            //     let found = subscriptions.find(s => {
            //         return s.productID == prod.id;
            //     });

            //     // c. if there is a subscription, update it with the new order, create it otherwise
            //     let subscription = null;
            //     if( _st.isNUE( found ) ) {
            //         // create new subscription
            //         subscription = new PetuSubscription();
            //         subscription.data.autoRenew         = this.paymentMethod == 'stripe';
            //         subscription.data.productID         = prod.id;
            //         subscription.data.customerID        = customer.data.id;
            //         subscription.data.remainingSessions = prod.subscriptionSessions;
            //         subscription.data.orderID           = order.data.id;
            //     } else {
            //         // update existing subscription
            //         subscription                        = new PetuSubscription(found)
            //         const remainingSessions             = subscription.data.remainingSessions
            //         subscription.data.remainingSessions = prod.subscriptionSessions
                    
            //         if (subscription.data && _st.isInteger(subscription.data.remainingSessions) && this.isInsideGracePeriod(subscription.data.nextPaymentDate)) {
            //             subscription.data.remainingSessions += remainingSessions
            //         }

            //         subscription.data.orderID           = order.data.id;
            //     }

            //     switch( prod.subscriptionFrequency ) {
            //         case SubscriptionFrequency.WEEKLY:
            //             subscription.data.nextPaymentDate = moment( order.data.orderDate ).add( 1, 'w' );
            //             break;
            //         case SubscriptionFrequency.MONTHLY:
            //             subscription.data.nextPaymentDate = moment( order.data.orderDate ).add( 1, 'M' );
            //             break;
            //         case SubscriptionFrequency.ANNUALLY:
            //             subscription.data.nextPaymentDate = moment( order.data.orderDate ).add( 1, 'y' );
            //             break;
            //         default:
            //             subscription.data.nextPaymentDate = moment( order.data.orderDate ).add( 1, 'M' );
            //             break;
            //     }

            //     await subscription.save();

            //     // 6. update product qty
            //     let product = new PetuProduct( prod );
            //     let qty = order.data.products.find(p => { return p.productId == prod.id }).qty;
            //     if (product.data.manageStock && (product.data.stock - qty) >= 0) {
            //         product.data.stock -= qty;
            //         await product.save()
            //     }
            // }

            return order.data.id;
        } catch(error) {
            console.error( `PROCESSING CHECKOUT: ${error}`);
            await order.delete();
            throw error;
            // return Promise.reject(error);
        }
    }
    async validate() { 
        this.errors = [];

        if( _st.isNUE( this.cart ) )            { this.errors.push( 'Cart is empty' ); return false; }
        if( _st.isNUE( this.paymentMethod ) )   { this.errors.push( 'Payment method not set' ); return false; }
        if( _st.isNUE( this.customer ) )        { this.errors.push( 'Customer is not set' ); return false; }

        // validate customer information
        let errors = [];
        let customer = new PetuCustomer( this.customer );
        let cart = new PetuCart( this.cart );

        if( _st.isNUE( customer.data.firstName ) )
            errors.push( 'Falta el primer nombre del cliente' );
        if( _st.isNUE( customer.data.lastName ) ) 
            errors.push( 'Falta el apellido del cliente.' );
        if( _st.isNUE( customer.data.email ) )
            errors.push( 'Falta el email del cliente' ); 
        if( _st.isNUE( customer.data.phone ) )
            errors.push( 'Falta el número de teléfono del cliente.');
        if( _st.isNUE( customer.data.billingAddress1 ) || _st.isNUE( customer.data.billingCity ) || _st.isNUE( customer.data.billingState ) || _st.isNUE( customer.data.billingZipcode ) )
            errors.push( 'Falta la dirección de facturación del cliente' );
        if( await cart.needShipping() && ( _st.isNUE( customer.data.shippingAddress1 ) || _st.isNUE( customer.data.shippingCity ) || _st.isNUE( customer.data.shippingState ) || _st.isNUE( customer.data.shippingZipcode ) ) )
            errors.push( 'Falta la dirección de envío del cliente' );
        // if( this.paymentMethod == 'stripe' && _st.isNUE( customer.data.stripeInfo.payment.id ) && _st.isNUE( this.cardToken ) )
        //     errors.push( 'Falta la información de pago' );

        // validate cart
        let cartValid = await cart.validate();
        if( cartValid !== true ) 
            errors.concat( cartValid );
        if( cart.data.products.length == 0 )
            errors.push( 'El carrito está vacío.' );
        
        if( errors.length > 0 ) {
            this.errors = errors;
            return false;
        }

        return true;
    }

    isInsideGracePeriod(date) {
        return new Date(date) > Date.now() - 1209600000
    }
}